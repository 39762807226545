<template>
    <div class="contact-container1">
        <div class="header-container3">
                <div class="header2">
                    <p class="have-questions">MASZ PYTANIA?</p>
                    <p class="big-bold">Skontaktuj się z nami </p>
                    <h3>Masz pytania? Wyślij nam wiadomość. Postaramy się odpowiedzieć na każde pytanie.</h3>
                </div>
                <div class="two-columns-container">
                    <div class="contact-container">
                        <div class="contact-box">
                            <img :src="images.mail"/>
                            <h4>Adres e-mail</h4>
                            <mail>biurolife@mendelhelps.pl</mail>
                        </div>
                        <div class="contact-box">
                            <img :src="images.facebook"/>
                            <h4>Facebook:</h4>
                            <tel>https://mendelhelpsfacebook.pl</tel>
                        </div>
                        <div class="contact-box">
                            <img :src="images.phone"/>
                            <h4>Instagram: </h4>
                            <tel>https://mendelhelpsfacebook.pl</tel>
                        </div>
                    </div>
                        <form @submit="sendMail">
                                <div class="input-field">
                                    <label>Imię i nazwisko</label><br/>
                                    <input type="text" ref="prodName" v-model="dataToSend.name"/>
                                </div>
                                <div class="input-field">
                                    <label>adres email</label>
                                    <input type="text" v-model="dataToSend.email"/>
                                </div>
                                <div class="input-field">
                                    <label>Treść wiadomości:</label><br>
                                    <span class="message-counter">{{ marksRemain }} / 300</span>
                                    <textarea rows="3" cols="55" maxlength="300" v-model="dataToSend.message"></textarea>
                                </div>
                                  <div>
                                    <p class="alert-ok">{{responseAlertOK}}</p>
                                    <p class="alert-error">{{responseAlertError}}</p>
                                  </div>
                            <div class="btn-container">
                                <input type="submit" value="Wyślij" @click="sendMail"/>
                            </div>
                        </form>
                </div>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import contact from '../photos/contact.jpeg';
import phone from "../photos/insta.png";
import mail from "../photos/mail.png";
import facebook from "../photos/facebook.png";
import insta from "../photos/insta.png";
export default {
    data() {
            return {
                dataToSend: {
                    name: '',
                    email: '',
                    message: ''
                },
                message: '',
                responseAlertOK: '',
                images: {
                    contactImage: contact,
                    phone: phone,
                    mail: mail,
                    facebook: facebook,
                    insta: insta
                },
            }
        },
          computed: {
            marksRemain() {
            var descLength = Number(this.message.length);
            var marks = 300 - descLength;
            return marks;
    }
  },
  methods: {
       sendMail() {
      event.preventDefault();
     // this.validateBeforeSend();
     // if(this.sendForm) {
      event.preventDefault();
    //   this.error.send = "";
    //   if (this.sendForm) {

       // var products = JSON.stringify(this.clientsCat);

        const formData = new FormData();
        formData.append("name", this.dataToSend.name);
        formData.append("mail", this.dataToSend.email);
        formData.append("message", this.dataToSend.message);
        // if(this.file !== ""){
        //   formData.append("file", this.file, this.file.name);
        // }

         axios.post("../PHPMailer/phpmailercontact.php", formData, {}).then((res) => {
          console.log(res);
          if(res.status !== 200) {
          this.responseAlertError = "Coś poszło nie tak. Sprawdź rozmiar zdjęcia i poprawność danych.";
            setTimeout(()=> {
              this.responseAlertError = ""
            }, 3000);
          } else if(res.status == 200) {
            this.responseAlertOK = "Wiadomość została wysłana.";
            setTimeout(()=> {
              this.responseAlertOK = "";
              location.reload();
              //router.push({ path: '/productsForm' })
            }, 5000
            )
          }
        });
      }
    //   } else {
    //     this.error.send = "Upewnij się, że wszystkie pola zostały poprawnie uzupełnione.";
    //   }
    },
  }
// }
</script>

<style scoped>
.contact-container1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header-container3 {
    margin: 30px 0;
    width: 1450px;
    border-radius: 20px;
    padding-bottom: 50px;
  color: #3084E2;
}
.have-questions {
  color: #3084E2;
    font-size: 17px;
    margin: 50px 0 0 0;
}
.big-bold {
    font-size: 35px;
    margin: 5px 0 0 0;
    font-weight: 600;
    color: #3084E2;
}
h3 {
  color: #3084E2;
}
.two-columns-container {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
  color: #3084E2;
  margin-top: 70px;
  height: 700px;
}
.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
    height: 100%;
    font-weight: 500;
}
.contact-box {
    width: 250px;
    height: 140px;
    background-color: #fff;
    padding: 20px;
    text-align: center;
    font-size: 16px;
    border-radius:20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.45) 0px 8px 32px;
}
.contact-box h4 {
    margin: 10px;
}
.contact-box img {
    height: 50px;
}
form {
    margin-top: 0 auto 0 auto;
    width: 400px;
    margin-left: 50px;
    height: 100%;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.45) 0px 8px 32px;
}
.form-container h3 {
  color: #3084E2;
}
.input-field {
  color: #3084E2;
    margin-top: 10px;
    margin: 30px;
}
.input-field label {
    font-size: 15px;
}
input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 3px 0;
  color: #3084E2;
  display: inline-block;
  border: 1px solid #747474;
  border-radius: 4px;
  box-sizing: border-box;
}
.btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
}
.be-partner {
    border-radius: 20px;
}
a button {
    color: white;
    border-radius: ;
}
a button:hover {
    color: black;
}
button {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 15px;
}

button:hover {
    background-color: #85BEFF;
    cursor: pointer;
}
input[type=submit] {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 18px;
    font-weight: 500;
    margin-top: 15px;
    width: 100%;
    border: none;
    color: white;
    margin: 25px 0;
    border-radius: 20px;
    cursor: pointer;
    margin: 10px;
}
input[type=submit]:hover {
    color: black;
    background-color: #3084E2;
}
textarea {
    width: 100%;
    height: 300px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #747474;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
}

input[type=submit]:hover {
  background-color: #6baaf2;
}

/*.form-container {
    width: 400px;
  margin-left: 50px;
  height: 100%;
}*/
.message-counter {
  align-self: flex-end;
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}
.alert-ok {
  color: green;
  font-size: 24px;
}
.alert-error {
  color: red;
  font-size: 24px;
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
.header-container3 {
    box-sizing: border-box;
    margin: 30px 0;
    width: 590px;
    padding-bottom: 50px;
    color: #3084E2;
}
.have-questions {
  color: #3084E2;
    font-size: 17px;
    margin: 30px 0 0 0;
}
.big-bold {
    font-size: 35px;
    margin: 5px 0 0 0;
    font-weight: 600;
    color: #3084E2;
}
h3 {
  color: #3084E2;
}
.two-columns-container {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
  color: #3084E2;
  margin-top: 50px;
  height: 650px;
}
.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    height: 100%;
    font-weight: 500;
}
.contact-box {
    width: 250px;
    height: 140px;
    background-color: #fff;
    padding: 20px;
    text-align: center;
    font-size: 16px;
    border-radius:20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.45) 0px 8px 32px;
}
.contact-box h4 {
    margin: 10px;
}
.contact-box img {
    height: 50px;
}
form {
    margin-top: 0 auto 0 auto;
    width: 400px;
    margin-left: 10px;
    height: 100%;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.45) 0px 8px 32px;
}
.form-container h3 {
  color: #3084E2;
}
.input-field {
  color: #3084E2;
    margin-top: 10px;
    margin: 20px;
}
.input-field label {
    font-size: 15px;
}
input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 3px 0;
  color: #3084E2;
  display: inline-block;
  border: 1px solid #747474;
  border-radius: 4px;
  box-sizing: border-box;
}
.btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
}
.be-partner {
    border-radius: 20px;
}
a button {
    color: white;
    border-radius: ;
}
a button:hover {
    color: black;
}
button {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
}

button:hover {
    background-color: #85BEFF;
    cursor: pointer;
}
input[type=submit] {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
    width: 100%;
    border: none;
    color: white;
    margin: 5px 0;
    border-radius: 20px;
    cursor: pointer;
    margin: 10px;
}
input[type=submit]:hover {
    color: black;
    background-color: #3084E2;
}
textarea {
    width: 100%;
    height: 300px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #747474;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
}

input[type=submit]:hover {
  background-color: #6baaf2;
}

/*.form-container {
    width: 400px;
  margin-left: 50px;
  height: 100%;
}*/
.message-counter {
  align-self: flex-end;
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}
}
@media only screen and (max-width: 600px) {
.header-container3 {
    box-sizing: border-box;
    margin: 30px 0;
    width: 100%;
    padding-bottom: 20px;
    color: #3084E2;
    margin:0;
}
.have-questions {
  color: #3084E2;
    font-size: 17px;
    margin: 30px 0 0 0;
}
.big-bold {
    font-size: 28px;
    margin: 5px 0 0 0;
    font-weight: 600;
    color: #3084E2;
}
h3 {
  color: #3084E2;
}
.two-columns-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
  color: #3084E2;
  margin-top: 50px;
  height: auto;
}
.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    font-weight: 500;
}
.contact-box {
    width: 100%;
    height: 140px;
    background-color: #fff;
    padding: 10px;
    margin: 10px 0;
    text-align: center;
    font-size: 16px;
    border-radius:20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.45) 0px 8px 32px;
}
.contact-box h4 {
    margin: 10px;
}
.contact-box img {
    height: 50px;
}
form {
    margin: 30px auto 0 auto;
    width: 100%;
    margin-left: auto;
    height: 100%;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.45) 0px 8px 32px;
}
.form-container h3 {
  color: #3084E2;
}
.input-field {
  color: #3084E2;
    margin-top: 10px;
    margin: 20px;
}
.input-field label {
    font-size: 15px;
}
input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 3px 0;
  color: #3084E2;
  display: inline-block;
  border: 1px solid #747474;
  border-radius: 4px;
  box-sizing: border-box;
}
.btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
}
.be-partner {
    border-radius: 20px;
}
a button {
    color: white;
    border-radius: ;
}
a button:hover {
    color: black;
}
button {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
}

button:hover {
    background-color: #85BEFF;
    cursor: pointer;
}
input[type=submit] {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
    width: 100%;
    border: none;
    color: white;
    margin: 5px 0;
    border-radius: 20px;
    cursor: pointer;
    margin: 10px;
}
input[type=submit]:hover {
    color: black;
    background-color: #3084E2;
}
textarea {
    width: 100%;
    height: 300px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #747474;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
}

input[type=submit]:hover {
  background-color: #6baaf2;
}

/*.form-container {
    width: 400px;
  margin-left: 50px;
  height: 100%;
}*/
.message-counter {
  align-self: flex-end;
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}
}
</style>