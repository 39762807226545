//import Vue from 'vue'
import { createMetaManager } from 'vue-meta'
import { createApp } from 'vue'
import { router  } from './routes.js'
import  store  from './store/index.js';
import App from './App.vue'
//import VueMeta from 'vue-meta'
const app = createApp(App);
//app.use(VueMeta);
app.use(store);
app.use(router);
app.use(createMetaManager());
app.mount('#app');
